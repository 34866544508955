<template>
  <div class="galleryPreview container has-text-centered">
    <h1 class="title"> {{pageName}}</h1>
        <div  v-if="pending">
        <div class="columns is-multiline">
      <div v-for="gallery in galleryPreview" :key="gallery.id" class="column is-one-fifth">
          <div class="card" v-if="gallery.BilderGallerie.length != 0">
              <div class="card-image is-hidden-mobile">
                   <b-image
                    :src="$axios.defaults.baseURL + gallery.BilderGallerie[0].formats.small.url"
                    ratio="1by1"
                    class="image "
                    alt="The Buefy Logo"
                    ></b-image>
              </div>
              <div class="card-content">
                  <h2 class="subtitle">{{gallery.Title}}</h2>
                  <router-link :to="'/gallerie/'+$route.params.tagName+'/' + gallery.id"> siehe Bilder 
                   <b-icon
                        icon="arrow-right-circle-outline"
                        size="is-small">
                   </b-icon>
                  </router-link>
              </div>
          </div>
      </div>
        </div>
        
        </div>  
        <div v-else class="box">
              <h1 class="title"> Loading . . .</h1>
        </div>
  </div>
</template>

<script>
export default {
    name:"galleryperview",
    data(){
        return {
            pending: false,
            count: 0,
            galleryPreview: null
        }
    },
    computed:{
        pageName(){
           let switchValue = ""
            switch(this.$route.params.tagName){
              
              case "hochzeitens": 
                switchValue = "Hochzeiten"
                break

              case "gasthaus": 
                switchValue = "Gasthaus"
                break
              
              case "sonstiges": 
                switchValue = "Events"
                break

              case "zimmers":
                  switchValue = "Zimmer"
                  break

            }
          return switchValue
        },
        countURL(){
            return this.$axios.defaults.baseURL+ "/" + this.$route.params.tagName + "/count"
        },
        galleryURL(){
            return this.$axios.defaults.baseURL+ "/" + this.$route.params.tagName
        }
    },
    async mounted(){
        this.reload()
    },
    methods:{
        async reload(){
            this.pending = false
            this.galleryPreview = null
            
            this.count = await this.$get(this.countURL)
            if (this.count > 0){
                this.galleryPreview = await this.$get(this.galleryURL)
            }
            this.pending = true
        }
    },
    watch: {
        '$route.params.tagName'(){
           this.reload()
        } 
    }
}
</script>

<style>

</style>